import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
export default function Invalidpage() {
    const navigate=useNavigate();
    useEffect(() => {
      navigate('/');
    }, [])
    
  return (
   <></>
  )
}
