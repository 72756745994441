import React from 'react';
import alert from "../common/Images/alert.svg";
import payme from "../common/Images/paymelogo.svg"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Failure() {

  const navigate=useNavigate();
  useEffect(() => {
   
    setTimeout(() => {
      // localStorage.clear();
      window.open(process.env.REACT_APP_CLOSING_URL,'_self');
    }, 3000);
  

    }, [])
  return (
    <div className="finalresults">
    <div className="logo">
      <img src={payme}></img>
    </div>

<div className='finalresultcontent'>
    <div className="content">
      <img src={alert}></img>
    <h1>Failed</h1>

        <p>
          Seems like there is some technical issue.
          <br />
          Please try again after some time
        </p>
    </div>
    </div>

  </div>
  )
}
