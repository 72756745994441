import React from 'react'
import payme from "../common/Images/paymelogo.svg"
import sucess from "../common/Images/sucess.svg"
import { useEffect } from 'react'
export default function Sucess() {

  const handlegoback=()=>{
    window.open(process.env.REACT_APP_CLOSING_URL,'_self');

}
useEffect(() => {
  setTimeout(()=>{
    handlegoback()
  },5000)
}, [])

  return (
    <div className="finalresults">
    <div className="logo">
      <img src={payme}></img>
    </div>

<div className='finalresultcontent'>
    <div className="content">
      <img src={sucess}></img>
    <h1>Success</h1>

    <p>
            Thank you! Your bank statement has <br />
            been submitted to PayMe
          </p>
    </div>
    </div>

  </div>
  )
}
