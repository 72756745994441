import React, { useState } from 'react'
import paymelogo from '../common/Images/paymelogo.svg' 
import bank from "../common/Images/Bankimage.svg"
import { useEffect } from 'react'
import { GetFipBankList ,GetSearchResultForApi,InitiateFipRequest,GetActionApi} from '../common/Apiservice'
import { Loader } from '../common/Loader'
import { useNavigate } from 'react-router-dom'
import { toast} from 'react-toastify';
export default function Home() {
 const [AllBanksinfo,SetAllBankInfo]=useState([])
 const [BankSearchResult,SetBankSearchResult]=useState([]);
 const [activesearch,Setactivesearch]=useState(false);
 const [Pending,SetPending]=useState(true);
const navigate=useNavigate();

 const HandleApiFailure=(error)=>{
    if(error.response.status=401){
             localStorage.clear();
             toast.error('Session Expired For User')
             SetPending(false)
        //  setTimeout(()=>{
        //      window.open(process.env.REACT_APP_CLOSING_URL,'_self');
        //  },800)
          
    }
    else{
        localStorage.setItem('encountered_error',1);
        navigate('/failed');
    }
 }

const GetAllBanksList=()=>{
    GetFipBankList().then((response)=>{
        SetAllBankInfo(response?.data);
        
        SetPending(false);
     }).catch((error)=>{
        HandleApiFailure(error);
     })
}

const handleclickBank=(value)=>{
SetPending(true)
    const data={
        fips:[value?.fip_id]
    }
InitiateFipRequest(data).then((response)=>{
  
    window.open(response?.data?.webview_url,'_self');
    setTimeout(()=>{
        SetPending(false);
    },[2000])
}).catch((error)=>{
    SetPending(false);
    HandleApiFailure(error);
})

}
const handleSearchFip=(searchvalue)=>{
    GetSearchResultForApi(searchvalue).then((response)=>{
        // console.log(response.data)
        SetBankSearchResult(response?.data)

        if(response?.data?.length>0){
            Setactivesearch(1);
        }else{
            Setactivesearch(0);
        }

    }).catch((error)=>{

        HandleApiFailure(error);
    })
}

const handleSearchChange=(e)=>{
    const searchvalue=e.target.value;
    console.log(searchvalue);
    setTimeout(() => {
        handleSearchFip(searchvalue);
    }, 800);
}

const GetIntiationStatus=()=>{
    GetActionApi().then((res)=>{
       
        if (res?.data?.status=='COMPLETED'){
        navigate('/success');
        }
        else if(res?.data?.status=='PROCESSING'){
            navigate('/pending');
        }
        else{
            GetAllBanksList();
        }
    }).catch((error)=>{
        HandleApiFailure(error);
    })
}
useEffect(() => {
    GetIntiationStatus()
      }, [])
      
  return (
      <div className='homepage'>
       {Pending?<Loader/>:<></>}
        <div className='navbar'>
            <p>Select Bank</p>
            <img src={paymelogo}></img>

        </div>

        <div className='searchbar'>
        <input type='text' spellcheck="false" placeholder='Search all banks '  onChange={handleSearchChange} />
        </div>

        <div className='banklisting'>

{activesearch==1 && BankSearchResult.map((val,key)=>{
    return(
        <div className='banks' onClick={(e)=>{e.preventDefault();handleclickBank(val)}} >
         <div className='mybank'>
         <img src={bank}></img>
          <span>{val?.fip_name}</span>
            </div>
          <hr></hr>
        </div>
    )
})}


{activesearch==0 && AllBanksinfo.map((val,key)=>{
    return(
        <div className='banks' onClick={(e)=>{e.preventDefault();handleclickBank(val)}} >
         <div className='mybank'>
         <img src={bank}></img>
          <span>{val?.fip_name}</span>
            </div>
          <hr></hr>
        </div>
    )
})}


        </div>
    </div>
  )
}
