import React from 'react'
import payme from "../common/Images/paymelogo.svg"
import pending from '../common/Images/Pendingicon.svg'
export default function Pending() {
    const handlegoback=(e)=>{
        e.preventDefault();
        window.location.replace(process.env.REACT_APP_CLOSING_URL);
    }
  return (
    <div className="finalresults">
    <div className="logo">
      <img src={payme}></img>
    </div>

<div className='finalresultcontent'>
    <div className="content">
      <img src={pending}></img>
    <h1>Pending</h1>
    <br></br>
        <br></br>
        <br></br>
    <span className="wait">Please wait</span>
        
    <p className='finalpending'>
    Please wait Your bank is taking longer than usual 
     to complete this process 
          </p>

          <div className="myybtn">
<button className="backbtn" onClick={handlegoback}>Go Back</button>
</div>
    </div>
    
 
    </div>

  </div>
  )
}
