
import '../src/style.scss' ;
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Failure from './components/Failure';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Sucess from './components/Sucess';
import Pending from './components/Pending';
import Invalidpage from './components/Invalipage';
import Callback from './components/Callback';
function App() {

  const urlString = window.location.href;
  const url = new URL(urlString);
  const token = url.searchParams.get('token');
  const platform=url.searchParams.get('platform');
  const user_type=url.searchParams.get('user_type')
  
  if(token!=null){
    localStorage.setItem('token',token);
  }


  return (
    <div className="App">
   <Router>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/failed" element={<Failure/>} />
      <Route path="/success" element={<Sucess/>} />
      <Route path="/pending" element={<Pending/>} />
      <Route path="*" element={<Invalidpage/>} />
      <Route path='/callback' element={<Callback/>} />
    </Routes>
    </Router>
    <ToastContainer  className="toastifyclass" />
    </div>
  );
}

export default App;
