import React from 'react'
import { Loader } from '../common/Loader'
import { useEffect,useState } from 'react'
import { GetActionApi } from '../common/Apiservice'
import { useNavigate } from 'react-router-dom'
export default function Callback() {
    const navigate=useNavigate();
  
const handleCheckCallBack=()=>{

GetActionApi().then((response)=>{

let status=response?.data?.status;
if (status=='COMPLETED') navigate('/success');
else if(status=='PROCESSING') navigate('/pending');
else if(status=='FAILED'||status== 'EXPIRED') navigate('/failed');
else navigate('/')

}).catch((error)=>{
    window.location.replace(process.env.REACT_APP_CLOSING_URL);
})
    }

    useEffect(() => {
   handleCheckCallBack()
    }, [])
    
  return (
    <div>
        <Loader loadingText='Please Wait ......'/>
    </div>
  )
}
