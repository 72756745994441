import axios from "axios";

export const GetFipBankList=()=>{
    return axios.get(process.env.REACT_APP_BASE_URL+"/ignosis/api/v1/fip-list/", {
        headers:{
          "Content-Type": "application/json",
          "Authorization":`Token ${localStorage.getItem('token')}`
        }
      });
}

export const GetSearchResultForApi=(searchvalue)=>{
    return axios.get(process.env.REACT_APP_BASE_URL+`/ignosis/api/v1/fip-list/?search=${searchvalue}`, {
        headers:{
          "Content-Type": "application/json",
          "Authorization":`Token ${localStorage.getItem('token')}`
        }
      });
}


export const InitiateFipRequest=(data)=>{
    return axios.post(process.env.REACT_APP_BASE_URL+`/ignosis/api/v1/initiate-request/`, data,{
        headers:{
          "Content-Type": "application/json",
          "Authorization":`Token ${localStorage.getItem('token')}`
        }
      });
}


export const GetActionApi=()=>{
  return axios.get(process.env.REACT_APP_BASE_URL+`/ignosis/api/v1/get-status/`,{
      headers:{
        "Content-Type": "application/json",
        "Authorization":`Token ${localStorage.getItem('token')}`
      }
    });
}